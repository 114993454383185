import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import apiClient from "../componets/http-common";
import "./Apione.css";
import { useRef, useState, useEffect } from "react";
import { Cat } from "../componets/types/types";
import CatCard from "../componets/CatCard";
import axios from "axios";
import Spinner from "react-bootstrap/esm/Spinner";
import MessageModal from "../componets/UI/MessageModal";
import { Alert } from "react-bootstrap";
import { getCats } from "../componets/Service/CatApi";


function Apione() {

  const [getResult2, setGetResult2] = useState<string[]>([]);
  const axios = require("axios");
  const [showAlert, setShowAlert] = useState(false);

  const get_id = useRef<HTMLInputElement>(null);
  const [showCard, setShowCard] = useState(false);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  let country = "";
  const [cats, setCats] = useState<
    {
      breed: string;
      country: string;
      orign: string;
      coat: string;
      pattern: string;
      countryMap: string;
    }[]
  >([]);

  async function getDataById() {
    const id:any = get_id.current?.value;
    if (+id > 50 || +id == 0) {
      setShowAlert(true);
      return;
    }

    setloadingSpinner(true);
    setCats([]);
    setShowCard(false);

    const res: any = await (getCats(id))
    let temp: Cat[] = JSON.parse(JSON.stringify(res.data.data, null, 2));
    setCats(temp);
  }

  async function intermediate() {
    cats.forEach(async (c, idx) => await getCountry(c.country, idx));
  }

  async function getCountry(name: string, index: number) {
    const options = {
      method: "GET",
      url: `https://restcountries.com/v3.1/name/${name}?fullText=true`,
    };

    let result: string = "";
    result = await axios
      .request(options)
      .then(function (response: { data: any }) {
        result = response.data[0].maps.googleMaps;
        handleCats(index, cats, result);
      })
      .catch(function (error: any) {
        console.log(name + " " + "Map Not Found");
        handleCats(index, cats, "Map Not Found");
      });
  }

  const handleCats = (index: number, cats: Cat[], result: string) => {
    const newCat = [...cats];
    newCat[index].countryMap = result;
    setCats(newCat);
  };
  
  function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    if (
      cats.length.toString() === get_id.current?.value &&
      showCard === false
    ) {
      intermediate();
      sleep(1500).then(() => {
        setloadingSpinner(false);
        setShowCard(true);
      }); // just so we can see the loading cycle
    }
  }, [cats]);


  return (
    <>
    {showAlert ?(
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Range of input needs to be between 1-50. (* ￣︿￣)
        </p>
      </Alert>
    ) : ("")
    }
      <div className="centered">
        <Form>
          <Form.Group className="col-sm-12 col-form-label">
            <Form.Label>Select Number</Form.Label>
            <Form.Control ref={get_id} type="number" min={0} max={50} />
          </Form.Group>
          {loadingSpinner ? (
            <Button variant="primary">
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          ) : (
            <Button onClick={getDataById}>Call Api</Button>
          )}
        </Form>
      </div>
      <div>
        {cats.map((temp: any, idx) => {
          console.log(" map =" + temp.countryMap);
          if (showCard === true) {
            return (
              <CatCard
                breed={temp.breed}
                country={temp.country}
                orign={temp.orign}
                coat={temp.coat}
                pattern={temp.pattern}
                countryMap={temp.countryMap}
                key={Math.random()}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
}

export default Apione;
