import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { Profile } from "./types/types";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<{
    googleId: null | string;
    imageUrl: null | string;
    email: null | string;
    name: null | string;
    givenName: null | string;
    familyName: null | string;
  }>({
    googleId: null,
    imageUrl: null,
    email: null,
    name: null,
    givenName: null,
    familyName: null,
  });

  // var admin = require("firebase-admin");

  // var serviceAccount = require("path/to/serviceAccountKey.json");

  // admin.initializeApp({
  //   credential: admin.credential.cert(serviceAccount),
  // });

  const clientId =
    "807902472508-vkq08nu3j1cps5d5pnn2k1nnc6otki5a.apps.googleusercontent.com";

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = (res: any) => {
    setProfile(res.profileObj);
    console.log("Logged in profile: ", profile);
  };

  const onFailure = (err: any) => {
    console.log("failed:", err);
  };

  const logOut = () => {
    setProfile({
      googleId: null,
      imageUrl: null,
      email: null,
      name: null,
      givenName: null,
      familyName: null,
    });

    console.log("Logged out profile: ", profile);
    navigate("/");
  };

  return (
    <>
      <Navbar bg="primary" variant="dark">
        <Container>
          <Navbar.Brand href="/">React03 Api</Navbar.Brand>
          {profile.googleId ? (
            <>
              <Form className="d-flex">
                <Nav className="me-auto">
                  <Nav.Link href="/apione">API ONE</Nav.Link>
                  <Nav.Link href="/formik">Formik </Nav.Link>
                  <Nav.Link href="/logintest">Login test</Nav.Link>
                </Nav>
              </Form>
              <GoogleLogout
                clientId={clientId}
                buttonText="Log out"
                onLogoutSuccess={logOut}
              />{" "}
            </>
          ) : (
            <GoogleLogin
              clientId={clientId}
              buttonText="Sign in with Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}
            />
          )}
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
