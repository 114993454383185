import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Cat } from "./types/types";



const CatCard = ({ breed, country, orign, coat, pattern, countryMap }: Cat) => {


  return (
    <div style={{ paddingTop: "1rem" }}>
      <div className="d-flex justify-content-center ">
        <div>
          <Card style={{ width: "18rem" }}>
            <div className="card text-center">
              <div className="card-header">{breed}</div>
              <div>
                country:{" "}
                {countryMap === "Map Not Found" || countryMap === "" ? (
                  <a href="/mapnotfound" style={{ color: "red" }}>
                    {country}
                  </a>
                ) : (
                  <a href={countryMap}>{country}</a>
                )}
              </div>
              <div>orign: {orign}</div>
              <div>coat: {coat}</div>
              <div>pattern: {pattern}</div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CatCard;

