import apiClient from "../http-common";
import { Cat } from "../types/types";

export const getCats = async (id:string) => {
    console.log('id: '+ id)
    const res: any = await apiClient.get(
      `https://catfact.ninja/breeds?limit=${id}`);
    return res;
  }

