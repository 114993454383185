import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import "./App.css";
import NavBar from "./componets/NavBar";
import LoginForm, { Props } from "./LoginForm";
import Apione from "./pages/Apione";

import BasicForm from "./pages/Formik";
import MapNotFound from "./pages/MapNotFound";

  const defaultProps: Props = {
    onPasswordChange() {
      return;
    },
    onRememberChange() {
      return;
    },
    onUsernameChange() {
      return;
    },
    onSubmit() {
      return;
    },
    shouldRemember: true,
  };

function App() {

  return (
    <>
      <NavBar></NavBar>
      <div>
        <Routes>
          <Route path="/apione" element={<Apione />} />
          <Route path="/formik" element={<BasicForm />} />
          <Route path="/mapnotfound" element={<MapNotFound />} />
          <Route path="/logintest" element={<LoginForm {...defaultProps} />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
